<template>
  <div>
    <button class="vs-button mr-1 md:mr-2 frill-container vs-button-primary vs-button-border p-1 sm:p-2"
      @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <div class="flex items-center">
        <img v-if="isHover" :src="svgUrlWhite" width="24" class="mr-1 md:mr-2">
        <img v-else :src="svgUrl" width="24" class="mr-1 md:mr-2">
        <span>What's New</span>
      </div>
    </button>
  </div>
</template>

<script>
export default {
  data: () => ({
    widget: undefined,
    config: undefined,
    svgUrl: require('@/assets/images/announcement-megaphone-green.svg'),
    svgUrlWhite: require('@/assets/images/announcement-megaphone-white.svg'),
    isHover: false
  }),
  mounted() {
    this.setupFrillWidget();
  },
  beforeUnmount() {
    if (this.widget) {
      this.widget.destroy();
      this.widget = undefined;
    }

    if (this.config) {
      window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
    }
  },
  methods: {
    async setupFrillWidget() {
      this.config = {
        // key: process.env.VUE_APP_FRILL_KEY, // <-- Add Script key here
        // widgets: [{
        key: process.env.VUE_APP_FRILL_WIDGET_KEY, // <-- Add Widget key here
        callbacks: {
          onReady(widget) {
            this.widget = widget
            console.log('Widget opened')
          },
          onOpen() {
            console.log('Widget opened')
          },
          onClose() {
            console.log('Widget closed')
          }
        },
        // }]
      };

      window.Frill_Config = window.Frill_Config || [];
      window.Frill_Config.push(this.config);

      if ('Frill' in window) {
        this.widget = await window.Frill('widget', this.config);
      }
    },
    mouseEnter() {
      this.isHover = true
    },
    mouseLeave() {
      this.isHover = false
    }
  }
}
</script>

<style lang="scss">
.frill-container {
  border-radius: .75rem;

  &:hover {
    background: rgba(var(--vs-primary), 1) !important;
    color: white !important
  }
}
</style>
